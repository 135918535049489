import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import promiseMemoize from "promise-memoize";

export const JWT_URL = "/api/v2/users/me/auth-token/";

/**
 * Fetches the JWT from the auth-token endpoint.
 * @param [withProjects] - The specific project(s) the JWT should be applicable to, if any.
 */
export async function fetchJWT({
  withProjects,
}: {
  withProjects?: number[];
}): Promise<string> {
  const result = await axios.get(JWT_URL, {
    params: {
      with_projects: withProjects?.join(","),
    },
  });

  if (!result.data?.token) {
    throw new Error("JWT fetch failed: Token not found in response.");
  }

  return result.data.token;
}

/**
 * Service client which will attach a cached or newly generated JWT.
 * @param [options] - The axios request options.
 * @param [withProjects] - The specific project(s) the JWT should be applicable to, if any.
 * @param [tokenExpiry=3000] - The expiry time for the JWT in ms. Defaults to 30,000ms (30s).
 */
const createServiceClient = ({
  options,
  withProjects,
  tokenExpiry = 30 * 1000, // Default 30 seconds
}: {
  options?: AxiosRequestConfig;
  withProjects?: number[];
  tokenExpiry?: number;
}) => {
  const axiosInstance = axios.create({
    ...options,
    xsrfCookieName: "sde-csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: { "X-Requested-With": "XMLHttpRequest", ...options?.headers },
  });

  const fetchCachedJWT =
    tokenExpiry > 0 // Cache returned JWT token when expiry time is included
      ? promiseMemoize(async () => fetchJWT({ withProjects }), {
          maxAge: tokenExpiry,
        })
      : async () => fetchJWT({ withProjects });

  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const token = await fetchCachedJWT();

      if (!config || !config.headers) {
        throw new Error("Unable to get request config: Missing Header");
      }

      config.headers.Authorization = `Bearer ${token}`;

      return config;
    }
  );

  return axiosInstance;
};

export default createServiceClient;
