import React from "react";
import { gettext } from "django-i18n";
import { isEqual } from "lodash";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WarningIcon from "@mui/icons-material/Warning";
import { makeStyles } from "@mui/styles";

import { theme } from "_common2/theme";

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: theme.palette.amber.main,
    borderRadius: "4px",
    color: theme.palette.grey87.main,
    fontWeight: "normal",
    marginTop: "20px",
    marginBottom:
      !isEqual(window.location.pathname, "/reporting/global/") &&
      !isEqual(window.location.pathname, "/reporting/training/")
        ? "-20px"
        : "0px",
  },
  link: {
    color: theme.palette.grey87.main,
    textDecoration: "underline",
    fontWeight: "500",
    "&:hover": {
      color: theme.palette.grey87.main,
    },
    "&:hover $linkIcon": {
      visibility: "visible",
    },
  },
  linkIcon: {
    color: theme.palette.grey60.main,
    height: "18px",
    width: "18px",
    visibility: "hidden",
    marginLeft: "4px",
    verticalAlign: "middle",
  },
  warningIcon: {
    color: theme.palette.grey87.main,
    marginLeft: "4px",
    marginRight: "-4px",
    opacity: 1,
  },
}));

interface ReportsDeprecationBannerProps {
  reportType: "global" | "training";
}

export const ReportsDeprecationBanner = ({
  reportType,
}: ReportsDeprecationBannerProps) => {
  const classes = useStyles();
  const bannerText =
    reportType === "global"
      ? gettext(
          "Not to worry, you can generate the same global reports using the Advanced Reports feature, which offers greater flexibility and customization. "
        )
      : gettext(
          "Not to worry, you can generate the same training report using the Advanced Reports feature, which offers greater flexibility and customization. "
        );
  return (
    <div className="sde-container">
      <Alert
        className={classes.content}
        icon={<WarningIcon className={classes.warningIcon} />}
        variant="filled"
      >
        <AlertTitle>
          {gettext(
            "This page is deprecated and will be removed in the 2025.2 release"
          )}
        </AlertTitle>
        {bannerText}
        {
          <a
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            href={
              "https://docs.sdelements.com/release/2024.4/guide/docs/reporting/advanced_reports.html"
            }
          >
            {gettext("Learn more about Advanced Reports.")}
            <OpenInNewIcon className={classes.linkIcon} />
          </a>
        }
      </Alert>
    </div>
  );
};
